<template>
    <div class="NotFoundWrappa">

        <img src="../assets/img/snowmans/lordbekket_snowman.jpg" title="LordBekket опять нажрался" class="snowMan"
             v-if="memId === 0">
        <img src="../assets/img/snowmans/exord_snowman.jpg" title="Exord успешно накидывается" class="snowMan"
             v-if="memId === 1">
        <img src="../assets/img/snowmans/iceman_snowman.jpg" title="Кто и за что убил IceMan'a 5300 лет назад?"
             class="snowMan" v-if="memId === 2">

        <div style="margin-top: 20px; color: white; font-size: 2em"> тут ничего нету (͡° ͜ʖ ͡°)</div>
        <div style="margin-top: 20px; color: white; font-size: 1em"> Как же так вышло? Снеговик знает - но он уволен
            /b
        </div>
        <div style="margin-top: 15px; font-size: 0.8em" @click="$root.redirect('/')" class="link"> Валим отсюда ?</div>


    </div>
</template>

<script>
export default {
    name: "NotFound",

    data() {
        return {
            memId: null,
        }
    },

    created() {
        this.memId = Math.floor(Math.random() * 3)
    }

}
</script>

<style scoped lang="scss">

@import "src/assets/scss/mixins";
@import "src/assets/scss/vars";

.NotFoundWrappa {
    display: flex;
    min-height: 100vh;
    overflow: hidden;
    background: #101010 no-repeat;
    background-size: cover;
    flex-direction: column;
    min-width: 1200px;
    align-items: center;
    justify-content: center;

    @include text-normalize();
    font-family: $main-font;
}

.snowMan {
    width: 600px;
    height: 400px;
    border-radius: 30px;
}

.link {
    color: #4f48b5;
    cursor: pointer;
    transition: all 0.3s ease;
}

.link:hover {
    color: #928cee;
    text-shadow: white 0 0 15px;
    transition: all 0.3s ease;
    transform: scale(1.2);
}

</style>